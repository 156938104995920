#root {
    font-family: 'Titillium Web';
    font-size: 18px;
    --primary-font-color: #1D1E26;
    --secondary-font-color: #6c6f80;
    --primary-color: #00169b;
    --secondary-color: #F24095;
    --grey-background-color: #FAFAFA;
}

#body {
    margin-top: 5%;
}

.container {
    max-width: 70%;
}

.nav-link {
    color: var(--primary-font-color) !important;
}

.nav-link:hover {
    color: var(--primary-color) !important;
    text-decoration: underline;
}

.btn > .nav-link {
    color: var(--bs-btn-color) !important;
    width: 100%;
    padding: 0;
}

.btn:hover > .nav-link {
    color: var(--bs-btn-color) !important;
    text-decoration: none;
}

.btn {
    font-size: 16px;
    border-radius: 12px;
    min-height: 42px;
    padding: 0 28px;
}

.white-button, .blue-button:hover {
    border: 1px solid var(--primary-color);
    --bs-btn-color: var(--primary-color);
    color: var(--bs-btn-color);
}

.blue-button, .white-button:hover {
    background-color: var(--primary-color);
    --bs-btn-color: white;
    color: var(--bs-btn-color);
}

.pink-border-button, .pink-button:hover {
    border: 1px solid var(--secondary-color);
    --bs-btn-color: var(--secondary-color);
    color: var(--bs-btn-color);
}

.pink-button, .pink-border-button:hover {
    background-color: var(--secondary-color);
    --bs-btn-color: white;
    color: var(--bs-btn-color);
}

.container-heading {
    font-size: 40px;
    font-weight: 700;
    margin: 5% 0;
}

input, textarea {
    border: none !important;
    border-bottom: 2px solid var(--primary-color) !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 2% 0 5%;
}

input::placeholder, textarea::placeholder {
    color: var(--secondary-font-color) !important;
}

input:focus, textarea:focus {
    box-shadow: none !important;
}

textarea {
    min-height: 210px !important;
}

.custom-file-label {
    background-color: #EEEEEE;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    cursor: pointer;
}

.custom-file, .custom-file-label, .custom-file-input {
    height: 50vh;
}

.small-input, .small-input > .custom-file-label, .small-input > .custom-file-input {
    height: 8vh;
    margin: 3% 0;
}

.small-input > .custom-file-label > img {
    width: 20%;
}

.small-input > .custom-file-label > .image-uploader-label {
    font-size: 12px;
}

.custom-file-label::after {
    display: none;
}

.custom-file-input {
    padding: 0 !important;
    cursor: pointer;
    z-index: 0;
}

.custom-radio .custom-control-input ~ .custom-control-label::before {
    border-radius: 5px;
    border-color: var(--primary-color);
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--primary-color);
    box-shadow: none;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none;
}

@media (max-width: 1440px) {
    .container {
        max-width: 75%;
    }
}

@media (max-width: 1318px) {
    .container {
        max-width: 90%;
    }
}

@media (max-width: 820px) {
    #body {
        margin-top: 15%;
    }

    .container-heading {
        font-size: 24px;
    }
}
