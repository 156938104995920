.loading {
    text-align: center;
    padding: 15%;
}

@media (max-width: 768px) {
    .loading > div {
        width: 100px !important;
        height: 100px !important;
    }
}
