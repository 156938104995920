.home-header {
    padding: 3% 0;
}

.home-header-heading {
    font-size: 48px;
    font-weight: 700;
}

.home-header-description {
    color: var(--secondary-font-color);
    font-size: 18px;
    margin: 5% 0;
    width: 65%;
}

.home-header > .row > .col:first-child {
    padding-top: 5%;
    padding-bottom: 5%;
}

.home-header button:first-child {
    margin-right: 2%;
}

.home-header button:last-child {
    margin-left: 2%;
}

.client-container {
    padding: 0;
}

.client-container .slick-track {
    display: flex;
}

.client-container .slick-track > .slick-slide {
    height: inherit;
}

.client-container .slick-track > .slick-slide > div {
    height: 100%;
}

.client-row {
    align-items: center;
    display: flex !important;
    width: auto !important;
    padding: 20% 0 10%;
    height: 100%;
}

.slick-slide img {
    margin: auto;
}

.product-container, .testimonial-container, .case-study-container {
    padding: 5% 0;
}

.product {
    padding: 0 5% 5%;
}

.product-image {
    margin-bottom: 5%;
}

.product-name {
    font-size: 24px;
    font-weight: 700;
    margin: 5% 0;
}

.product-description {
    color: var(--secondary-font-color);
}

.testimonial-container {
    padding-top: 0;
}

.testimonial {
    text-align: left;
    border: 2px solid #00169b1a;
    border-radius: 32px;
    padding: 5%;
}

.testimonial-container .slick-slide > div {
    margin: 0 2%;
}

.testimonial-name {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--primary-color);
    font-weight: 700;
    margin-top: 5%;
}

.testimonial-company {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #545666;
}

.about-us-container {
    padding: 5% 0;
    background-color: var(--grey-background-color);
}

.about-us-description {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.case-study-container {
    min-height: 45vw;
}

.case-study-row {
    top: 0;
    transform: none;
    right: 0;
}

.case-study-row > .col {
    margin-bottom: 3%;
    padding: 0;
}

.case-study-row > .col:nth-child(1n) {
    padding-right: 2%;
}

.case-study-row > .col:nth-child(2n) {
    padding-right: 0;
    padding-left: 2%;
}

.about-us-desktop-image {
    display: block;
}

.about-us-mobile-image {
    display: none;
}

@media (min-height: 1100px) {
    .home-header {
        padding: 10% 0;
        height: 65vh;
    }
}

@media (min-height: 1300px) {
    .home-header {
        padding: 12% 0;
    }
}

@media (max-width: 1440px) {
    .about-us-description > .product-description {
        width: 65% !important;
    }

    .case-study-container {
        min-height: 55vw;
    }

    .case-study-row {
        margin-left: 8%;
    }
}

@media (max-width: 1250px) {
    .testimonial > .row > .col-3 {
        width: 25%;
    }

    .testimonial > .row > .col-9 {
        width: 75%;
    }

    .case-study-row {
        margin-left: 0;
    }

    .case-study-row > .col > div {
        margin: 0 !important;
        width: 100% !important;
        height: 100% !important;
    }
}

@media (max-width: 1100px) {
    .home-header {
        padding: 5% 0;
    }
}

@media (max-width: 1032px) {
    .home-header > .row img {
        width: 100%;
    }

    .product-description, .about-us-description > .product-description {
        width: 100% !important;
    }

    .about-us-container > .row img {
        width: 100% !important;
    }
}

@media (max-width: 820px) {
    .home-header {
        padding: 10% 0;
    }

    .home-header, .about-us-container, .case-study-container {
        text-align: center;
    }

    .home-header > .row, .testimonial-container > .container > .row, .about-us-container > .row,
    .case-study-container > .container > .row {
        display: block;
    }

    .home-header-heading {
        font-size: 32px;
    }

    .home-header > .row img, .case-study-container > .container > .row > .col > img,
    .home-header-description {
        width: 100%;
    }

    .client-row > .col {
        width: 50%;
        margin-bottom: 5%;
    }

    .product-container > .container > .row-cols-3 > .col, .case-study-row > .col {
        width: 100%;
        margin-bottom: 10%;
    }

    .testimonial {
        margin: 10% 0 !important;
    }

    .about-us-container > .row {
        max-width: 90%;
        margin: auto !important;
    }

    .about-us-container > .row > .col {
        margin: 10% 0 !important;
    }

    .about-us-description {
        position: relative;
        top: 0;
        transform: none;
    }

    .about-us-desktop-image {
        display: none;
    }

    .about-us-mobile-image {
        display: block;
    }

    .case-study-container > .container > .row {
        margin: 0;
    }

    .case-study-row {
        margin: 10% 0;
        display: block;
    }

    .case-study-row > .col {
        padding: 0 !important;
    }

    .product-name {
        font-size: 20px;
    }
}
