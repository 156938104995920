.services-container > .container > .row > .col {
    border-radius: 32px;
    background-size: cover !important;
    color: white;
    padding: 5%;
}

.services-container > .container > .row > .col:first-child {
    background: url("../images/partnerships.jpg") no-repeat center;
    margin-right: 2%;
}

.services-container > .container > .row > .col:last-child {
    background: url("../images/extended-teams.jpg") no-repeat center;
    margin-left: 2%;
}

.services-description {
    width: 75% !important;
}

@media (max-width: 1100px) {
    .technology-container > .container > .row > .col p, .technology-container > .container > .row > .col img {
        width: 100% !important;
    }
}

@media (max-width: 820px) {
    .services-container > .container > .row > .col {
        margin: 10% 0 !important;
    }
}
