.navbar-header {
    font-size: 16px;
    background-color: white;
    padding: 0.5% 0;
}

.navbar-brand > img {
    height: 5vmin;
}

.navbar-collapse > .navbar-nav:nth-child(2) {
    width: 60%;
}

.nav-item {
    width: 100%;
}

.nav-link {
    width: fit-content;
    margin: auto;
}

.navbar .navbar-toggler {
    border-color: transparent;
    box-shadow: none;
}

.navbar .navbar-toggler:focus {
    outline: none;
}

.navbar .navbar-toggler-icon {
    background-image: url("../images/menu.svg");
}

.navbar .navbar-toggler[aria-expanded="true"] > .navbar-toggler-icon {
    background-image: url("../images/close.svg");
}

.navbar > div {
    padding: 0;
}

.active {
    color: var(--primary-color) !important;
    font-weight: bold !important;
}

@media (max-width: 992px) {
    .navbar-collapse {
        height: 100vh;
        margin-top: 5%;
    }

    .navbar-nav > .nav-item {
        margin: 5% 0;
        text-align: center;
    }

    .navbar-collapse > .navbar-nav:nth-child(2) {
        width: 100%;
    }
}

@media (max-width: 820px) {
    .navbar-collapse {
        margin-top: 10%;
    }

    .navbar-brand > img {
        height: 7vmin;
    }

    .navbar-nav > .nav-item > .nav-link {
        font-size: 24px;
    }
}
