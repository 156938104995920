.case-study {
    margin-bottom: 5%;
}

.technology {
    background-color: var(--primary-color);
    color: white;
    width: fit-content;
    padding: 0.2% 2%;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 1%;
}

.case-study-description, .about-us-description > .case-study-description {
    width: 100% !important;
}

@media (max-width: 1100px) {
    .case-study > .col > img {
        width: 100%;
        object-fit: cover;
    }

    .case-study > .col > .about-us-description {
        position: relative;
        top: 0;
        transform: none;
    }
}

@media (max-width: 820px) {
    .case-study {
        display: block;
        margin: 0;
    }

    .case-study .product-name {
        margin: 5% 0 !important;
    }

    .case-study .case-study-description {
        margin-top: 5%;
    }

    .case-study .about-us-description > div {
        text-align: center;
    }

    .case-study img {
        width: 100% !important;
        height: 100% !important;
    }
}
