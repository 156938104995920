.rooster-filter {
    display: none;
}

.rooster-job {
    border: 2px solid #00169b1a;
    border-radius: 32px;
    padding: 5%;
    margin: 2% 0 5%;
}

.rooster-job a {
    color: rgb(10, 10, 10);
    text-decoration: underline;
}

.rooster-job h3 {
    padding: 0;
    margin: 0 0 0.4rem 0;
    font-size: 1.2rem;
    font-weight: 600;
    text-decoration: none;
}

.rooster-job h4 {
    padding: 0;
    margin: 0;
    display: block;
    font-size: 0.9rem;
    font-weight: 600;
}

.rooster-department-header {
    display: none;
}

.rooster-job .data-row {
    padding: 1% 0;
    color: var(--secondary-color);
}

.rooster-job .inline-value {
    padding-right: 0.6rem;
    margin-right: 0.6rem;
}

.rooster-job .inline-value:not(:last-child) {
    border-right: 1px solid rgba(10, 10, 10, 0.1);
}

.chips .chip {
    margin-right: 0.6rem;
    background-color: var(--primary-color);
    color: white;
    width: fit-content;
    padding: 0.2% 2%;
    border-radius: 8px;
    display: inline-block;
    margin-bottom: 1%;
}

.chips .chip:not(:last-child) {
    border-right: 1px solid rgba(10, 10, 10, 0.1);
}
