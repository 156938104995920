.footer {
    background-color: var(--grey-background-color);
    padding-top: 5%;
}

.footer > .container {
    padding: 0;
}

.footer .navbar-nav {
    flex-direction: row;
}

.footer .navbar-nav .nav-link {
    margin: inherit;
    font-weight: 600;
}

.logo {
    height: 5vmin;
}

hr {
    color: #DADCE5;
    opacity: 1;
    margin: 5% 0 0;
}

.rights {
    color: #545666;
    font-size: 14px;
    padding: 2% 0;
}

.address-container > .d-flex {
    width: fit-content;
    margin-left: 45%;
}

.desktop-image {
    display: block;
}

.mobile-image {
    display: none;
}

@media (max-width: 1440px) {
    .address-container > .d-flex {
        margin-left: 35%;
    }
}

@media (max-width: 1250px) {
    .address-container > .d-flex {
        margin-left: 15%;
    }
}

@media (max-width: 820px) {
    .footer {
        padding-bottom: 10%;
        padding-left: 10%;
        padding-right: 10%;
    }

    .footer > .container > .row {
        display: block;
    }

    .footer > .container > .row > .col {
        margin: 10% 0;
    }

    .logo {
        height: 7vmin;
    }

    .address-container > .d-flex {
        margin: 0;
    }

    .desktop-image {
        display: none;
    }

    .mobile-image {
        display: block;
    }

    .navbar-nav > .nav-item {
        width: 100% !important;
    }

    .footer .navbar-nav .nav-link {
        margin: auto;
        font-size: 16px;
    }
}
