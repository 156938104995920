.about-us-header {
    padding-bottom: 0;
}

.about-us-header-image {
    border-radius: 30px 30px 0 0;
    width: 100%;
}

.about-us-header-description {
    font-size: 18px;
    margin: 3% auto 5%;
    width: 80%;
}

.founders-container {
    background-color: var(--grey-background-color);
    padding: 5% 0;
}

.founders-container > .container {
    padding: 0;
}

.founders-description, .about-us-description > .founders-description {
    width: 100% !important;
}

.founder-images-container {
    padding: 0;
    margin-left: 6.5%;
}

.founder-images-container > div {
    height: fit-content;
}

.founder-images-container > div:first-child {
    margin-right: 5%;
}

.founder-images-container > div:last-child {
    margin-left: 5%;
}

.founder-images-container > div > img {
    border-radius: 32px;
    width: 100%;
}

.overlay {
    position: absolute;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    color: white;
    width: 100%;
    padding: 20px;
    border-radius: 0 0 32px 32px;
}

.case-study-row .overlay {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
}

.overlay > h4 {
    font-size: 18px;
    font-weight: 700;
}

.overlay > p {
    font-size: 14px;
}

@media (max-width: 1710px) {
    .founders-description {
        margin-top: 10%;
    }
}

@media (max-width: 1653px) {
    .founders-description {
        margin-top: 20%;
    }
}

@media (max-width: 1565px) {
    .founders-description {
        margin-top: 30%;
    }
}

@media (max-width: 1500px) {
    .founder-images-container {
        margin: 0;
    }
}

@media (max-width: 1440px) {
    .founders-description {
        margin-top: 20%;
    }
}

@media (max-width: 1358px) {
    .founders-container {
        min-height: 75vh;
    }

    .founders-description {
        margin-top: 30%;
    }
}

@media (max-width: 1315px) {
    .founders-container {
        min-height: auto;
    }

    .founders-description {
        margin-top: 0;
    }
}

@media (max-width: 1230px) {
    .founders-description {
        margin-top: 10%;
    }
}

@media (max-width: 1200px) {
    .founders-container > .container > .row > .col > img {
        width: 100%;
        object-fit: cover;
    }
}

@media (max-width: 1150px) {
    .founders-container > .container > .row > .col > .about-us-description {
        position: relative;
        top: 0;
        transform: none;
    }
}

@media (max-width: 1000px) {
    .founder-images-container > div {
        margin: 0 !important;
    }

    .founder-images-container > div > img {
        width: 100%;
        object-fit: cover;
    }

    .founder-images-container > div:first-child {
        margin-right: 1% !important;
    }

    .founder-images-container > div:last-child {
        margin-left: 1% !important;
    }
}

@media (max-width: 820px) {
    .about-us-header-description {
        margin: 5% auto;
    }

    .founders-container {
        text-align: center;
    }

    .founders-container > .container > .row {
        display: block;
        margin: auto;
    }

    .founders-container > .container > .row > .col {
        margin: 0 !important;
    }

    .founder-images-container > div {
        margin: 0 0 5% !important;
    }

    .founders-container > .container > .row > .col > img {
        margin: 5% 0;
    }
}
