.blog-container > .row > .col > img {
    width: 100%;
    object-fit: cover;
}

.blog-container > .row > .col > div {
    padding: 0 10% 0 0;
}

.blog-container > .row > .col.second > div {
    padding: 0 5%;
}

.blog-container > .row > .col.third > div {
    padding: 0 0 0 10%;
}

.ce-block__content {
    margin: 0;
    max-width: 90%;
}

.codex-editor__redactor {
    padding-bottom: 0 !important;
}

.ce-block__content img {
    border-radius: 32px;
}

.image-tool__caption, .cdx-simple-image__caption {
    display: none;
}

@media (max-width: 1100px) {
    .blog-container > .row > .col {
        width: 50%;
    }

    .blog-container > .row > .col:nth-child(odd) > div {
        padding: 0 5% 0 0;
    }

    .blog-container > .row > .col:nth-child(even) > div {
        padding: 0 0 0 5%;
    }
}

@media (max-width: 820px) {
    .blog-container > .row {
        display: block;
        margin: 0;
    }

    .blog-container > .row > .col > div {
        padding: 0 !important;
    }

    .blog-author-container > .row {
        display: flex;
        text-align: left;
    }

    .blog-author-container > .row > .col-1 {
        width: 25%;
    }

    .blog-author-container > .row > .col-11 {
        width: 75%;
    }

    .blog-author-container > .home-header-heading {
        text-align: left;
        margin: 5% 0;
        font-size: 24px !important;
    }

    .ce-block__content {
        max-width: 100%;
    }

    .blog-container > .row > .col, .blog-container > .row > .col > img {
        width: 100%;
        object-fit: cover;
    }

    .blog-container > .row > .col {
        margin: 10% 0 !important;
    }
}
