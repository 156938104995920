.login-component {
    width: 50%;
    border: 2px solid var(--primary-color);
    padding: 5%;
    text-align: left;
    margin: 10% auto;
    border-radius: 32px;
}

.login-button {
    width: 100% !important;
    padding: 2%;
    margin-top: 5%;
    margin-left: 0 !important;
}

.portal-container-navbar {
    display: flex;
}

.vertical-nav {
    text-align: left;
    padding: 0;
    width: 15%;
    background-color: #F5F5F5;
}

.vertical-nav > .nav {
    position: fixed;
    width: 12%;
}

.vertical-nav .nav-link {
    color: var(--primary-font-color) !important;
    margin: 0 0 5%;
    text-align: left;
    padding-left: 10%;
    padding-right: 10%;
    width: 100%;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: transparent;
    color: var(--primary-color) !important;
    font-weight: bold;
    border-radius: 0;
}

.link-arrow {
    display: none;
}

.nav-pills .nav-link.active > .link-arrow {
    display: initial;
    margin-right: 5%;
}

.nav-detail-container {
    width: 85%;
    padding: 2%;
}

.portal-form {
    width: 30%;
}

.form-heading {
    margin-bottom: 10%;
}

.form-button {
    width: 100%;
    margin-bottom: 5%;
}

.portal-tab-container > .tab-pane > div > .row {
    width: 50%;
    margin-top: 5%;
}

.portal-tab-container > .tab-pane > div > .row > .col > button {
    width: 50%;
}

.portal-tab-container > .tab-pane .red-button:hover {
    background-color: transparent;
}

.portal-table > thead {
    background-color: #F5F5F5;
}

.portal-table > tbody {
    color: var(--secondary-font-color);
}

.portal-table th, .portal-table td {
    padding: 2% 0;
}

.action-container {
    display: flex;
    align-items: center;
    margin-bottom: 1%;
}

.action-container > .action-button-container {
    margin-left: auto;
    width: 15%;
}

.action-container > .action-button-container > button:first-child {
    margin-right: 5%;
}

.action-container > .action-button-container > button:last-child {
    margin-left: 5%;
}

.custom-control-label {
    margin: 0.5% 0;
}

.portal-table > tbody textarea {
    min-height: calc(1.5em + .75rem + 2px) !important;
    height: calc(1.5em + .75rem + 2px);
}

.image-column > img {
    width: 50%;
}

.action-column {
    text-align: right;
}

.action-column > button {
    color: var(--secondary-color);
    background-color: transparent;
    border: none;
}

.text-editor {
    border: 2px solid var(--primary-color);
    padding: 2%;
    border-radius: 12px;
    width: 50%;
}

.select {
    padding: 0;
    height: inherit;
}

.radio-button-container {
    margin: 2% 0;
}
